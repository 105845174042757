import React from 'react'
import get from 'tcweb-material-components/core/poly/get'
import { makeStyles } from '@material-ui/core/styles'
import Box from 'tcweb-material-components/core/box'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from 'classnames'
import PropTypes from 'prop-types'

import PlaceholderImage from './placeholderImg'
import Link from './link'

export const IMAGE_HEIGHT_MOBILE = '110px'
export const IMAGE_HEIGHT_DESKTOP = '110px'
export const IMAGE_POSITION_START = 'start'
export const IMAGE_POSITION_END = 'end'

const useClasses = makeStyles(({ breakpoints }) => ({
    container: { maxWidth: 'inherit' },
    image: {
        lineHeight: 1,
        height: ({ imageSizes }) => `${imageSizes.mobile || IMAGE_HEIGHT_MOBILE} !important`,
        width: ({ imageSizes }) => `${imageSizes.mobile || IMAGE_HEIGHT_MOBILE} !important`,
        objectFit: 'cover',
        [breakpoints.up('lg')]: {
            height: ({ imageSizes }) => `${imageSizes.desktop || IMAGE_HEIGHT_DESKTOP} !important`,
            width: ({ imageSizes }) => `calc((${imageSizes.desktop || IMAGE_HEIGHT_DESKTOP} / 9) * 16) !important`
        }
    },
}))

const PostCard = ({
    heading,
    subHeading,
    headingVariant,
    ariaLabel,
    to,
    image,
    imageSizes,
    imagePosition,
    disableImage,
    forceSquareImage,
    iconComponent,
    classes,
    textContainerProps,
    variant,
    ...rest
}) => {
    const _classes = useClasses({ imageSizes: imageSizes })
    const { subHeading: subHeadingClass, heading: headingClass, image: imageClass, container: containerClass, ...buttonClasses } = classes
    const ImageComponent = !!image ? (
        <GatsbyImage
            image={get(image, 'localFile.childImageSharp.gatsbyImageData')}
            alt={get(image, 'altText')}
            className={classnames(_classes.image, { [imageClass]: !!imageClass })} />
    ) : (
        <PlaceholderImage name='gearsOfWar' className={classnames(_classes.image, { [imageClass]: !!imageClass })} />
    )

    return (
        <Link
            aria-label={ariaLabel}
            title={ariaLabel}
            to={to}
            classes={buttonClasses}
            {...rest}
        >
            <Box className={classnames(_classes.container, containerClass)}>
                {!!iconComponent && iconComponent}
                {imagePosition === IMAGE_POSITION_START && ImageComponent}
                <Box {...textContainerProps}>
                    {subHeading &&
                        <Typography className={classnames( 'h5', { [subHeadingClass]: !!subHeadingClass })}>
                            {subHeading}
                        </Typography>
                    }
                    {heading &&
                        <Typography variant={headingVariant} className={classnames( 'h3', { [headingClass]: !!headingClass })}>
                            {heading}
                        </Typography>
                    }
                </Box>
                {imagePosition === IMAGE_POSITION_END && ImageComponent}
            </Box>
        </Link>)
}

PostCard.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    headingVariant: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    image: PropTypes.object,
    imageSizes: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string
    }),
    imagePosition: PropTypes.oneOf([IMAGE_POSITION_START, IMAGE_POSITION_END]),
    forceSquareImage: PropTypes.bool,
    iconComponent: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string,
        headings: PropTypes.string,
        subHeading: PropTypes.string,
        image: PropTypes.string
    }),
    textContainerProps: PropTypes.object
}

PostCard.defaultProps = {
    headingVariant: "h2",
    forceSquareImage: false,
    iconComponent: null,
    image: null,
    imagePosition: IMAGE_POSITION_END,
    imageSizes: {
        mobile: IMAGE_HEIGHT_MOBILE,
        desktop: IMAGE_HEIGHT_DESKTOP
    },
    textContainerProps: null
}

export default PostCard
