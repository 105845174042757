import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Box from 'tcweb-material-components/core/box'
import map from 'tcweb-material-components/core/poly/map'
import Typography from '@material-ui/core/Typography'
import scratchMaskEven from '../../assets/images/scratch-container-rectangle-1.svg'
import scratchMaskOdd from '../../assets/images/scratch-container-rectangle-2.svg'
import bg from '../../assets/images/backgrounds/secondary/bg-secondary-xs.jpg'
import useLocation from '../../hooks/useLocation'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'

import { defaultLocale, getLocaleFromWPMLLocale } from '../../utilities/locale'
import PostCard, { IMAGE_HEIGHT_DESKTOP, IMAGE_HEIGHT_MOBILE } from './postCard'
import { decodeText } from '../../utilities/helpers'

const useClasses = makeStyles(({ palette, breakpoints }) => ({
    root: {
        background: `url(${bg})`,
        paddingRight: 0,
        maxWidth: 'none',
        '&:not(:last-child)': { borderBottom: `1px solid #b4b4b4` },
        '&:hover, &:focus': {
            outline: 'none',
            textDecoration: 'none',
            background: `url(${bg})`,
            '& h2': {
                textDecoration: 'underline'
            }
        },
        [breakpoints.up('md')]: {
            '&:not(:last-child)': { borderBottom: 'none' },
            padding: '30px',
            marginBottom: '15px',
            '&:nth-child(even)': {  maskBoxImage: `url(${scratchMaskEven})` },
            '&:nth-child(odd)': {  maskBoxImage: `url(${scratchMaskOdd})` },
        }
    },
    relatedPostsItem: {
        display: 'flex',
        justifyContent: 'space-between',
        '& div:first-child': {
            minHeight: IMAGE_HEIGHT_MOBILE,
            width: `calc(100% - ${IMAGE_HEIGHT_MOBILE})`,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            [breakpoints.up('md')]: {
                width: `calc(100% - ((${IMAGE_HEIGHT_DESKTOP} / 9) * 16))`,
            }
        },
    },
    subHeading: { color: palette.text.primary }
}))


const RelatedPosts = ({ postList, ...rest }) => {
    const _classes = useClasses()
    const { t } = useTranslation()
    const { locale } = useLocation()

    // Use loc'd title if available
    map((post) => {
        let localizedPost = find((p) => getLocaleFromWPMLLocale(p.locale.locale) === locale, get(post, 'translated'))
        if(locale !== defaultLocale && !!localizedPost){
            post.title = localizedPost.title
        }
    }, postList)


    return (!!postList && postList.length > 0) && (
        <Box
            position='relative'
            display='flex'
            alignItems='center'
            paddingY={{ xs: '20px', md: '100px'}}
            flexDirection='column'
            {...rest}
        >
            <Typography align='center' variant="h2" className="h2" >{t('Related Posts')}</Typography>
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                width={{ xs: '100%', sm: '600px', md: '760px' }}
                paddingY={{ xs: '20px', md: '30px' }}
            >
                {map((post, i) => (
                    <PostCard
                        key={i}
                        classes={{
                            root: _classes.root,
                            container: _classes.relatedPostsItem,
                            subHeading: _classes.subHeading,
                        }}
                        ariaLabel={t('slug:related-post-articletitle', { articleTitle: decodeText(post.title) })}
                        to={post.slug}
                        textContainerProps={{ padding: { xs: '15px', md: 0 } }}
                        heading={decodeText(post.title)}
                        headingVariant='h3'
                        subHeading={post.formattedDate}
                        image={get(post, 'featuredImage.node')}
                        forceSquareImage={true}
                        target='_self'
                    />
                ), postList)}
            </Box>
        </Box>
    )
}

export default RelatedPosts
