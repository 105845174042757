import React, { useState  } from 'react';
import queryString from 'query-string';
import get from 'tcweb-material-components/core/poly/get';
import { useMutation } from "@apollo/client";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { ApolloProvider } from '@apollo/client'
import Cookie from 'js-cookie'
import { CircularProgress } from '@material-ui/core';
import PageTemplate from '../../templates/page';
import PostTemplate from '../../templates/post';

import LayoutContent from '../../components/layout/layoutContent'

// TODO
const LOGIN_JWT = gql`
    mutation LoginUser {
        login( input: {
            clientMutationId: "uniqueId",
            username: "${process.env.GATSBY_WORDPRESS_JWT_USERNAME}",
            password: "${process.env.GATSBY_WORDPRESS_JWT_PASSWORD}"
        } ) {
            authToken
        }
    }
`;

//sends a header if there is a token in cookies, if not sends null
const apolloHeader = Cookie.get('token') ? `Bearer ${Cookie.get('token')}` : null

const defaultApolloOptions = {
    cache: new InMemoryCache(),
    uri: `${process.env.GATSBY_WORDPRESS_DOMAIN}/graphql`,
    headers: {
        authorization: apolloHeader
    },
}


const Helper = ({query, id, postType, extraProps}) => {
    const [completed, setCompleted] = useState(false)
    const [login, { data: loginData, loading: loginLogin, error: errorLogin }] = useMutation(LOGIN_JWT, /*{ errorPolicy: "all" }, */ {
        onCompleted({login}) {
            if (login) {
                localStorage.setItem('token', get(login, 'authToken') );
                const fiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000)
                Cookie.set('token',get(login, 'authToken'), {expires: fiveMinutes})
                setCompleted(true)
            }
        }
    });
    if(!completed) login()

    //pleasant loading stage 1
    if (loginLogin) return (
        <LayoutContent>
            <div style={{ width:'100%', height:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <>Fetching data...</>
                <CircularProgress />
            </div>
        </LayoutContent>
    )
    if (errorLogin) return <p>Error: ${errorLogin.message}</p>;
    return (
        <>
            {!!completed &&
                <Query
                    query={query}
                    variables={{id}}
                >
                    {({ data, loading, error }) => {
                        //pleasant loading stage 2
                        if (loading) return (
                            <LayoutContent>
                                <div style={{ width:'100%', height:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <>Processing data...</>
                                    <CircularProgress />
                                </div>
                            </LayoutContent>
                        )

                        if (error) return <p>Error: ${error.message}</p>;
                        const title = get(data, `${postType}.title`, '')
                        const content = get(data, `${postType}.content`, '')

                        //switch between PageTemplate or PostTemplate depending on postType
                        if (postType === 'page') {
                            const newProps = {...extraProps}
                            newProps.data.page.content = content
                            newProps.data.page.title = title

                            return (
                                <div>
                                    <PageTemplate {...newProps} />
                                </div>
                            )
                        } else {
                            const newProps = {...extraProps}

                            newProps.data.page.content = content
                            newProps.data.page.title = title
                            return (
                                <PostTemplate {...newProps}/>
                            )
                        }
                    }}
                </Query>
            }
        </>
    )
}

const withPreview = (args = { preview: false, postPreview: false }) => Component => {
    const preview = (props) => {
        const client = new ApolloClient({ ...defaultApolloOptions })
        const parsed = queryString.parse(props.location.search);
        const { postType, postId } = parsed;
        // Id needs to be an int for preview query.
        const id = parseInt(postId, 10);
        /**
         * If no preview param, return the component with the preview props as false.
         */
        if (!args.preview || !postType) {
            return (
                <>
                    Failed to load
                </>
            );
        }
        /**
         * Otherwise, run our Apollo query.
         */
        if (postType === 'post') {
            return(
                <ApolloProvider client={client}>
                    <Helper query={args.postPreview} id={id} postType={postType} extraProps={props}/>
                </ApolloProvider>
            )
        }

        return (
            <ApolloProvider client={client}>
                <Helper query={args.preview} id={id} postType={postType} extraProps={props}/>
            </ApolloProvider>
        )
    };
  return preview;
};

export default withPreview;