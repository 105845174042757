import React, { useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Image from 'tcweb-material-components/core/image'
import Box from 'tcweb-material-components/core/box'
import Section from 'tcweb-material-components/core/section'
import Facebook from 'tcweb-material-components/core/social/facebook'
import Instagram from 'tcweb-material-components/core/social/instagram'
import Twitch from 'tcweb-material-components/core/social/twitch'
import Twitter from 'tcweb-material-components/core/social/twitter'
import YouTube from 'tcweb-material-components/core/social/youtube'

import Link from '../common/link'
import PushNotificationContext from '../context/pushNotificationContext'
import jackbot from '../../assets/images/jackbot.png'
import scratchLine from '../../assets/images/scratch-line.svg'

const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const useClasses = makeStyles(({ gutters, breakpoints }) => ({
    image: {
        [breakpoints.down('sm')]: {
            margin: 'auto',
            width: '75%',
            paddingBottom: '20px',
        },
        [breakpoints.only('sm')]: {
            width: '50%'
        },
        [breakpoints.up('lg')]: {
            height: 'auto',
            maxWidth: '25vw'
        }
    },
    imageContainer: {
        padding: '20px',
        [breakpoints.only('md')]: {
            display: 'none'
        },
        [breakpoints.up('lg')]: {
            padding: '0 20px 0 0',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: gutters.page.lg
        }
    },
    optInContainer: {
        paddingBottom: '25px',
        backgroundImage: `url(${scratchLine})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginBottom: '20px',
            [breakpoints.up('md')]: {
                marginBottom: '30px'
            }
        }
    },
    scratchBorder: {
        width: '100%'
    },
    socialIcon: {
        margin: '0 10px'
    }
}))

const PostBanner = () => {
    const { t } = useTranslation()
    const { subscription, canReceivePushNotification } = useContext(PushNotificationContext)
    const _classes = useClasses()

    return (
        <Box
            className='align-wide bg-notification-cta'
            position='relative'
            display='flex'
            justifyContent='center'
            flexDirection={{ xs: 'column', md: 'row' }}
            component='section'
            padding={{ xs: '20px', md: '50px' }}
        >
            {(!subscription && canReceivePushNotification && PUSH_NOTIFICATION_ENABLED) &&
                <Box className={_classes.imageContainer}>
                    <Image src={jackbot} alt={t('Jackbot default skin')} className={_classes.image} />
                </Box>
            }
            <Box display='flex' justifyContent='center' justifySelf='stretch'>
                <Section
                    width={{ lg: 'calc(100vw / 3) !important' }}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    itemMargins={{ mobile: '20px', tablet: '20px', desktop: '20px' }}
                >
                    {(!subscription && canReceivePushNotification && PUSH_NOTIFICATION_ENABLED) && (
                        <Box className={_classes.optInContainer}>
                            <Typography variant='h2' align='center'>{t('Get Notified')}</Typography>
                            <Typography variant='h3' className='h3' align='center'>{t("Don't miss out on news updates with web push notifications")}</Typography>
                            <Link href='/notifications' color='primary' variant='contained'>{t('Opt-in now')}</Link>
                        </Box>
                    )}
                    <Section display='flex' flexDirection='column' alignItems='center' itemMargins={{ mobile: '20px', tablet: '20px', desktop: '20px' }}>
                        <Box><Typography align='center'>{t('Follow us for the latest in the world of Gears of War')}</Typography></Box>
                        <Box display='flex'>
                            <Twitter className={_classes.socialIcon} href='https://twitter.com/gearsofwar' target='_blank' rel='noopener noreferrer' />
                            <Facebook className={_classes.socialIcon}  href='https://www.facebook.com/gearsofwar' target='_blank' rel='noopener noreferrer' />
                            <Twitch className={_classes.socialIcon}  href='https://www.twitch.tv/gearsofwar/' target='_blank' rel='noopener noreferrer' />
                            <YouTube className={_classes.socialIcon}  href='https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw' target='_blank' rel='noopener noreferrer' />
                            <Instagram className={_classes.socialIcon}  href='https://www.instagram.com/gearsofwar' target='_blank' rel='noopener noreferrer' />
                        </Box>
                        <Typography align='center'>
                            <Trans
                                i18nKey='slug:visit-the-help-center-for-game-support'
                                components={[<a href="https://support.gearsofwar.com/" target="_blank">{t('Help Center')}</a>]}
                            />
                        </Typography>
                    </Section>
                </Section>
            </Box>
        </Box>
    )
}

export default PostBanner
