import React from 'react'
import get from 'tcweb-material-components/core/poly/get'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import Box from 'tcweb-material-components/core/box'

import { getLocaleFromWPMLLocale } from '../../utilities/locale'
import PostCard, { IMAGE_HEIGHT_MOBILE, IMAGE_POSITION_START } from './postCard'
import { decodeText } from '../../utilities/helpers'

const IMAGE_HEIGHT_DESKTOP = '130px'

const useClasses = makeStyles(({ palette, breakpoints, accessibility: { highContrast }, gutters }) => ({
    root: {
        display: 'flex',
        backgroundColor: palette.common.black,
        justifyContent: 'center',
        [breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    linkRoot: {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        padding: 0,
        borderTop: `1px solid ${palette.secondary.border}`,
        borderBottom: `1px solid ${palette.secondary.border}`,
        '&:hover, &:focus': {
            outline: 'none',
            textDecoration: 'none',
            backgroundColor: palette.common.black,
            '& h2': {
                color: palette.primary.light,
                textDecoration: 'underline'
            }
        },
    },
    linkLabel: {
        display: 'block'
    },
    linkContainer: {
        flex: 1,
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.common.black,
        color: palette.common.white,
        [highContrast.mediaQuery('black-on-white')]:{
            '& svg': {
                color: palette.common.black
            }
        },
        [breakpoints.up('md')]: {
            flex: ({ hasPrev, hasNext }) => (!hasPrev || !hasNext) ? 1 : 0.5
        }
    },
    leftLink: {
        justifyContent: 'flex-start',
        paddingRight: 0,
        paddingLeft: gutters.page.sm,
        borderBottom: `1px solid ${palette.secondary.border}`,
        '& div': {
            textAlign: 'left'
        },
        '& svg': {
            marginRight: gutters.page.sm,
            [breakpoints.up('md')]: {
                marginRight: gutters.page.md,
            },
        },
        [breakpoints.up('md')]: {
            maxWidth: ({ hasNext }) => !hasNext && '760px',
            paddingLeft: ({ hasNext }) => hasNext ? gutters.page.md : 0,
            border: 'none',
            borderRight: ({ hasNext }) => hasNext && `1px solid ${palette.secondary.border}`
        }
    },
    reverse: { flexDirection: 'row-reverse' },
    rightLink: ({ hasPrev }) => ({
        justifyContent: 'flex-end',
        paddingLeft: 0,
        paddingRight: gutters.page.sm,
        '& div': {
            textAlign: 'right',
        },
        '& svg': {
            marginLeft: gutters.page.sm,
            [breakpoints.up('md')]: {
                marginLeft: gutters.page.md,
            },
        },
        [breakpoints.up('md')]: {
            maxWidth: !hasPrev && '760px',
            paddingRight: hasPrev ? gutters.page.md : 0,
            '& div': {
                textAlign: hasPrev ? 'right' : 'left'
            }
        },
    }),
    content: {
        padding: '0 20px',
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: IMAGE_HEIGHT_MOBILE,
        [breakpoints.up('lg')]: {
            padding: '0 30px',
            height: IMAGE_HEIGHT_DESKTOP
        }
    },
    label: {
        color: '#757575'
    },
    heading: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        [breakpoints.up('sm')]: {
            '-webkit-line-clamp': 1
        }
    },
    rotate: { transition: 'transform 200ms', transform: 'rotate(180deg)' }
}))

const PostControls = ({ next, prev, isTranslation, locale, ...rest }) => {
    const _classes = useClasses({ hasNext: !!next, hasPrev: !!prev })
    const { t } = useTranslation()

    if (!next && !prev) return null

    const prevTranslation = get(prev, 'translated', []).find(t => getLocaleFromWPMLLocale(t.locale.locale) === locale)
    const nextTranslation = get(next, 'translated', []).find(t => getLocaleFromWPMLLocale(t.locale.locale) === locale)
    const prevTitle = get(prevTranslation, 'title', '') || get(prev, 'title', '')
    const nextTitle = get(nextTranslation, 'title', '') || get(next, 'title', '')

    return (
        <>
            <Box {...rest} className={_classes.root}>
                {prev && (
                    <PostCard
                        variant='contained'
                        classes={{
                            root: _classes.linkRoot,
                            container: `${_classes.linkContainer} ${_classes.leftLink}`,
                            subHeading: _classes.label,
                            heading: _classes.heading
                        }}
                        ariaLabel={t('slug:newer-post-articletitle', { articleTitle: decodeText(prevTitle) })}
                        to={prev.path || `/${prev.slug}`}
                        textContainerProps={{ className: _classes.content }}
                        heading={decodeText(prevTitle)}
                        subHeading={t('Newer Post')}
                        image={get(prev, 'featuredImage.node')}
                        imageSizes={{ desktop: IMAGE_HEIGHT_DESKTOP }}
                        imagePosition={IMAGE_POSITION_START}
                        forceSquareImage={true}
                        iconComponent={<FontAwesomeIcon size='2x' icon={['tcs', 'arrow-right']} className={_classes.rotate} color='white' />}
                    />
                )}

                {next && (
                    <PostCard
                        variant='contained'
                        classes={{
                            root: _classes.linkRoot,
                            container: `${_classes.linkContainer} ${_classes.rightLink} ${_classes.reverse}`,
                            subHeading: _classes.label,
                            heading: _classes.heading
                        }}
                        ariaLabel={t('slug:older-post-articletitle', { articleTitle: decodeText(nextTitle) })}
                        to={next.path || `/${next.slug}`}
                        textContainerProps={{ className: _classes.content }}
                        heading={decodeText(nextTitle)}
                        subHeading={t('Older Post')}
                        image={get(next, 'featuredImage.node')}
                        imageSizes={{ desktop: IMAGE_HEIGHT_DESKTOP }}
                        imagePosition={IMAGE_POSITION_START}
                        forceSquareImage={true}
                        iconComponent={<FontAwesomeIcon size='2x' icon={['tcs', 'arrow-right']} color='white' />}
                    />
                )}
            </Box>
        </>
    )
}

export default PostControls
