import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import get from 'tcweb-material-components/core/poly/get'
import find from 'tcweb-material-components/core/poly/find'
import orderBy from 'lodash/orderBy'
import { makeStyles } from '@material-ui/core/styles'
import Box from 'tcweb-material-components/core/box'
import Section from 'tcweb-material-components/core/section'
import count from 'tcweb-material-components/core/poly/count'
import classNames from 'classnames'
import { gql } from '@apollo/client'

import { decodeText } from '../utilities/helpers'
import PostBanner from '../components/notifications/postBanner'
import TableOfContents from '../components/common/tableOfContents'
import PostControls from '../components/common/postControls'
import RelatedPosts from '../components/common/relatedPosts'
import LayoutContent from '../components/layout/layoutContent'
import options from './components/'
import { defaultWPMLLocale } from '../utilities/locale'
import withPreview from '../components/common/withPreview'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    content: {
        paddingTop: '3em',
        paddingBottom: '4em',
        paddingRight: theme.gutters.page.xs,
        paddingLeft: theme.gutters.page.xs,
        [theme.breakpoints.only('sm')]: {
            paddingRight: theme.gutters.page.md,
            paddingLeft: theme.gutters.page.md
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 'auto auto',
            width: '50%'
        },
        '& .alignfull': {
            [theme.breakpoints.up('md')]: {
                marginLeft: '-50%',
                width: 'calc(100% + 100%)',
                maxWidth: 'calc(100% + 100%)'
            }
        },
        '& .alignwide': {
            [theme.breakpoints.up('md')]: {
                marginLeft: `calc(-50% + ${theme.gutters.page.lg})`,
                width: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`,
                maxWidth: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`
            }
        }
    },
    featuredImage: {
        maxHeight: `${(!!global && !!global.window ? global.window.innerHeight : 500) * 0.65}px`
    },
    featuredImageDefault: {
        width: '100%'
    },
    featuredImageDefaultContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginTop: '.25em',
        marginBottom: '.25em'
    }
}))

const PostTemplate = ({ isDraft, ...props }) => {
    const title = get(props, 'data.page.title', '')
    const content = get(props, 'data.page.content', '')
    const excerpt = get(props, 'data.page.excerpt', '')
    const formattedDate = get(props, 'data.page.formattedDate', '')
    const englishNode = find(i => get(i, 'locale.locale') === defaultWPMLLocale, get(props, 'data.page.translated', []))

    const { data } = props
    const classes = useStyles()

    const cleanExcerpt = (!!excerpt && decodeText(excerpt)) || ''

    const pageContent = (
        <div className={classes.content}>
            <time className='h4'>{formattedDate}</time>
            <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
            <Section>
                <div>{parse(content, options)}</div>
            </Section>
        </div>
    )

    // TOC
    const hasToc = get(englishNode, 'toc.hasTableOfContents', get(data, 'page.toc.hasTableOfContents'))
    const tocItems = orderBy(get(props, 'pageContext.tableOfContents'), ['order', 'date'], ['asc', 'asc'])
    const showToc = hasToc && count(tocItems) > 0

    return (
        <LayoutContent {...props} mainClass={classNames('wp-content', classes.postContent)} setBackgroundImage>
            <Helmet
                meta={[
                    // Twitter Card Data
                    {
                        name: 'twitter:title',
                        content: title
                    },
                    !!get(data, 'page.featuredImage.node') ? {
                        name: 'twitter:image',
                        content: `${get(data, 'site.siteMetadata.siteUrl')}${get(
                            data,
                            'page.featuredImage.node.localFile.childImageSharp.gatsbyImageData.src'
                        )}`
                    } : {},
                    {
                        name: 'twitter:image:alt',
                        content: get(data, 'page.featuredImage.node.altText') || get(data, 'site.siteMetadata.socialAlt')
                    },
                    {
                        name: 'twitter:description',
                        content: cleanExcerpt
                    },
                    {
                        property: 'og:title',
                        content: title
                    },
                    !!get(data, 'page.featuredImage.node') ? {
                        name: 'image',
                        property: 'og:image',
                        content: `${get(data, 'site.siteMetadata.siteUrl')}${get(
                            data,
                            'page.featuredImage.node.localFile.childImageSharp.gatsbyImageData.src'
                        )}`
                    } : {},
                    {
                        property: 'og:image:alt',
                        content: get(data, 'page.featuredImage.node.altText') || get(data, 'site.siteMetadata.socialAlt')
                    },
                    {
                        property: 'og:description',
                        content: cleanExcerpt
                    }
                ]}
            />
            {showToc ? (
                <Box className={classes.root}>
                    {pageContent}
                    <TableOfContents items={tocItems} isDraft={isDraft} />
                </Box>
            ) : (
                pageContent
            )}
            {/* <PostBanner /> */}
            <PostControls prev={props.data.prevPost} next={props.data.nextPost} locale={props.pageContext.locale} />
            <RelatedPosts postList={get(props, 'data.page.related')} />
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query($id: String, $prevId: String, $nextId: String) {
        page: wpSitePost(id: { eq: $id }) {
            title
            content
            excerpt
            formattedDate: date(formatString: "MMM D, YYYY")
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            # toc { hasTableOfContents }
            translated {
                title
                excerpt
                formattedDate: date(formatString: "MMM D, YYYY")
                locale {
                    locale
                }
                # toc { hasTableOfContents }
            }
            related {
                title
                slug
                date
                formattedDate: date(formatString: "MMM D, YYYY")
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                            }
                        }
                    }
                }
                translated {
                    title
                    locale {
                        locale
                    }
                }
            }
        }
        prevPost: wpSitePost(id: { eq: $prevId }) {
            title
            slug
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp{
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            translated {
                title
                locale {
                    locale
                }
            }
        }
        nextPost: wpSitePost(id: { eq: $nextId }) {
            title
            slug
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp{
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            translated {
                title
                locale {
                    locale
                }
            }
        }
        site {
            siteMetadata {
                socialAlt
                siteUrl
            }
        }
    }
`

export default PostTemplate
